<template>
  <product
    title="Amazfit GTR 3 Pro"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    isSwitchPrice=true
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100027281150.html"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'Amazfit-GTR-3-Pro',
  components: {
    Product
  },
  data () {
    return {
      slogan:
        '数字表冠｜血压健康研究｜全方位健康监测｜Zepp OS 丰富手表小程序生态｜12 天超长续航',
      colors: ['曜石黑', '戈壁棕'],
      price: ['1099', '1199'],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/2_pro_04.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_1.jpg',
        'https://site-cdn.huami.com/files/amazfit/GTR3Pro_2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_4.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_5.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_6.jpg',
        'https://site-cdn.huami.com/files/amazfit/GTR3Pro_7.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_8.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_9.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_10.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_11.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/12-2.png',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTR-3pro.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_14.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr3-pro/GTR3Pro_15.jpg',
        'https://site-cdn.huami.com/files/amazfit/GTR3Pro_16.jpg'
      ]
    }
  },
  methods: {}
}
</script>

<style></style>
